module.exports = {
  en: {
    path: 'en',
    name: 'English',
    default: true
  },
  es: {
    path: 'es',
    name: 'Español'
  }
};
